import * as React from "react";
import { Routes, CRoutes } from "../../config/Routes";
import telmdcareLogoWht from "../../../img/telemdcare-logo-wht.webp";
import dApple from "../../../img/download-apple-store.webp";
import dGoogle from "../../../img/download-google-play.webp";
import { LegitScript } from "./LegitScript";
import { BBBscript } from "./BBBscript";
import { Row, Col } from "reactstrap";
import '../../../css/footer.css'

export interface IProps {
    children?: React.ReactNode
}

export interface IState {
}
export default class Footer extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props)
        this.state = {
        }
    }

    render() {

        return (
            <footer className="footer bg-dark">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 h-100 my-auto">
                            <div className="d-flex flex-row">
                                <h5 className="text-white logo-font">
                                    <img loading="lazy" src={telmdcareLogoWht} />
                                </h5>
                                <ul className="list-inline mb-2 ml-3 mt-2">
                                    <li className="list-inline-item mr-3">
                                        <a href="https://www.linkedin.com/company/telmdcare/" data-toggle="tooltip" title="" data-original-title="LinkedIn" target="_blank">
                                            <i className="fab fa-linkedin fa-2x fa-fw"></i>
                                        </a>
                                    </li>
                                    <li className="list-inline-item mr-3">
                                        <a href="https://www.facebook.com/Telmdcare/" data-toggle="tooltip" title="" data-original-title="Facebook" target="_blank">
                                            <i className="fab fa-facebook fa-2x fa-fw"></i>
                                        </a>
                                    </li>
                                    <li className="list-inline-item">
                                        <a href="https://www.instagram.com/telmdcare/" data-toggle="tooltip" title="" data-original-title="Instagram" target="_blank">
                                            <i className="fab fa-instagram fa-2x fa-fw"></i>
                                        </a>
                                    </li>
                                    <li className="list-inline-item">
                                        <a href="https://www.youtube.com/channel/UCtFNOE7t-nGUwVDN6RY5kgg" data-toggle="tooltip" title="" data-original-title="Youtube" target="_blank">
                                            <i className="fab fa-youtube fa-2x fa-fw"></i>
                                        </a>
                                    </li>
                                    <li className="list-inline-item">
                                        <a href="https://g.page/telmdcare" data-toggle="tooltip" title="" data-original-title="Google SEO" target="_blank">
                                            <i className="fab fa-google fa-2x fa-fw"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <Row>
                                <Col>
                                    <ul className="list-inline mb-2">
                                        <li className="list-unstyled">
                                            <a href={Routes.about}>About</a>
                                        </li>
                                        <li className="list-unstyled">
                                            <a href={Routes.contact}>Contact</a>
                                        </li>
                                        <li className="list-unstyled">
                                            <a href={Routes.faq}>FAQ</a>
                                        </li>
                                        <li className="list-unstyled">
                                            <a href={Routes.termsOfUse}>Terms of Use</a>
                                        </li>
                                        <li className="list-unstyled">
                                            <a href={Routes.privacyPolicy}>Privacy Policy</a>
                                        </li>
                                    </ul>
                                </Col>
                                <Col>
                                    <ul className="list-inline mb-2">
                                        <li className="list-unstyled">
                                            <a href={CRoutes.blog}>Blog</a>
                                        </li>
                                        <li className="list-unstyled">
                                            <a href={CRoutes.siteMap}>Sitemap</a>
                                        </li>
                                    </ul>
                                </Col>
                                <Col>
                                    <div>
                                        <Row>
                                            <Col>
                                                <LegitScript id="legit-script-footer" />
                                            </Col>
                                            <Col>
                                                <div className="h-100 my-auto d-inline-block" style={{ right: 'auto' }}>
                                                    <BBBscript id="bbb-script-footer" />
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <div className="col-lg-6 h-100 my-auto">
                                    <h6>Our service is available in the following states</h6>
                                </div>
                            </Row>
                            <Row>
                                <Col>
                                    <ul className="list-inline mb-2">
                                        <li className="list-unstyled">
                                            <a href={CRoutes.alabama}>Alabama</a>
                                        </li>
                                        <li className="list-unstyled">
                                            <a href={CRoutes.arizona}>Arizona</a>
                                        </li>
                                        <li className="list-unstyled">
                                            <a href={CRoutes.colorado}>Colorado</a>
                                        </li>
                                        <li className="list-unstyled">
                                            <a href={CRoutes.florida}>Florida</a>
                                        </li>
                                        <li className="list-unstyled">
                                            <a href={CRoutes.idaho}>Idaho</a>
                                        </li>
                                        <li className="list-unstyled">
                                            <a href={CRoutes.texas}>Texas</a>
                                        </li>
                                    </ul>
                                </Col>
                                <Col>
                                    <ul className="list-inline mb-2">
                                        <li className="list-unstyled">
                                            <a href={CRoutes.illions}>Illions</a>
                                        </li>
                                        <li className="list-unstyled">
                                            <a href={CRoutes.indiana}>Indiana</a>
                                        </li>
                                        <li className="list-unstyled">
                                            <a href={CRoutes.iowa}>Iowa</a>
                                        </li>
                                        <li className="list-unstyled">
                                            <a href={CRoutes.kansas}>Kansas</a>
                                        </li>
                                        <li className="list-unstyled">
                                            <a href={CRoutes.kentucky}>Kentucky</a>
                                        </li>
                                        <li className="list-unstyled">
                                            <a href={CRoutes.delaware}>Delaware</a>
                                        </li>
                                    </ul>
                                </Col>
                                <Col>
                                    <ul className="list-inline mb-2">
                                        <li className="list-unstyled">
                                            <a href={CRoutes.michigan}>Michigan</a>
                                        </li>
                                        <li className="list-unstyled">
                                            <a href={CRoutes.minnesota}>Minnesota</a>
                                        </li>
                                        <li className="list-unstyled">
                                            <a href={CRoutes.nebraska}>Nebraska</a>
                                        </li>
                                        <li className="list-unstyled">
                                            <a href={CRoutes.nevada}>Nevada</a>
                                        </li>
                                        <li className="list-unstyled">
                                            <a href={CRoutes.newJersey}>New Jersey</a>
                                        </li>
                                    </ul>
                                </Col>
                                <Col>
                                    <ul className="list-inline mb-2">
                                        <li className="list-unstyled">
                                            <a href={CRoutes.northDakota}>NorthDakota</a>
                                        </li>
                                        <li className="list-unstyled">
                                            <a href={CRoutes.oklahoma}>Oklahoma</a>
                                        </li>
                                        <li className="list-unstyled">
                                            <a href={CRoutes.southDakota}>South Dakota</a>
                                        </li>
                                        <li className="list-unstyled">
                                            <a href={CRoutes.tennessee}>Tennessee</a>
                                        </li>
                                        <li className="list-unstyled">
                                            <a href={CRoutes.utah}>Utah</a>
                                        </li>
                                    </ul>
                                </Col>
                                <Col>
                                    <ul className="list-inline mb-2">
                                        <li className="list-unstyled">
                                            <a href={CRoutes.washington}>Washington</a>
                                        </li>
                                        <li className="list-unstyled">
                                            <a href={CRoutes.wisconsin}>Wisconsin</a>
                                        </li>
                                        <li className="list-unstyled">
                                            <a href={CRoutes.oregon}>Oregon</a>
                                        </li>
                                        <li className="list-unstyled">
                                            <a href={CRoutes.maine}>Maine</a>
                                        </li>
                                        <li className="list-unstyled">
                                            <a href={CRoutes.maryland}>Maryland</a>
                                        </li>
                                    </ul>
                                </Col>
                                <Col>
                                    <ul className="list-inline mb-2">
                                        <li className="list-unstyled">
                                            <a href={CRoutes.montana}>Montana</a>
                                        </li>
                                        <li className="list-unstyled">
                                            <a href={CRoutes.louisiana}>Louisiana</a>
                                        </li>
                                        <li className="list-unstyled">
                                            <a href={CRoutes.vermont}>Vermont</a>
                                        </li>
                                        <li className="list-unstyled">
                                            <a href={CRoutes.guam}>Guam</a>
                                        </li>
                                        <li className="list-unstyled">
                                            <a href={CRoutes.mississippi}>Mississippi</a>
                                        </li>
                                    </ul>
                                </Col>
                                <Col>
                                    <ul className="list-inline mb-2">
                                        <li className="list-unstyled">
                                            <a href={CRoutes.newHampshire}>New-Hampshire</a>
                                        </li>
                                        <li className="list-unstyled">
                                            <a href={CRoutes.westVirginia}>West-Virginia</a>
                                        </li>
                                        <li className="list-unstyled">
                                            <a href={CRoutes.wyoming}>Wyoming</a>
                                        </li>
                                        <li className="list-unstyled">
                                            <a href={CRoutes.georgia}>Georgia</a>
                                        </li>
                                        <li className="list-unstyled">
                                            <a href={CRoutes.ohio}>Ohio</a>
                                        </li>
                                    </ul>
                                </Col>
                            </Row>
                            <Row>
                                <div className="col-lg-6 h-100 my-auto">
                                    <h6>What we treat</h6>
                                </div>
                            </Row>
                            <Row>
                                <Col>
                                    <ul className="list-inline mb-2">
                                        <li className="text-white font-weight-bold">
                                            Acute Care
                                        </li>
                                        <li className="list-unstyled">
                                            <a href={CRoutes.allergies}>Allergies</a>
                                        </li>
                                        <li className="list-unstyled">
                                            <a href={CRoutes.bronchitis}>Bronchitis</a>
                                        </li>
                                        <li className="list-unstyled">
                                            <a href={CRoutes.coldSores}>Cold Sores</a>
                                        </li>
                                        <li className="list-unstyled">
                                            <a href={CRoutes.covidQuestions}>Covid Questions</a>
                                        </li>
                                        <li className="list-unstyled">
                                            <a href={CRoutes.dentalInfection}>Dental Infection</a>
                                        </li>
                                        <li className="list-unstyled">
                                            <a href={CRoutes.earInfections}>Ear Infection</a>
                                        </li>
                                        <li className="list-unstyled">
                                            <a href={CRoutes.goutAttack}>Gout Attack</a>
                                        </li>
                                        <li className="list-unstyled">
                                            <a href={CRoutes.motionSickness}>Motion Sickness</a>
                                        </li>
                                        <li className="list-unstyled">
                                            <a href={CRoutes.nauseaVomiting}>Nausea/Vomiting</a>
                                        </li>
                                        <li className="list-unstyled">
                                            <a href={CRoutes.oralThrush}>Oral Thrush</a>
                                        </li>
                                        <li className="list-unstyled">
                                            <a href={CRoutes.painRelief}>Pain Relief</a>
                                        </li>
                                        <li className="list-unstyled">
                                            <a href={CRoutes.pinkEye}>Pink Eye</a>
                                        </li>
                                        <li className="list-unstyled">
                                            <a href={CRoutes.respiratoryInfection}>Respiratory Infection</a>
                                        </li>
                                        <li className="list-unstyled">
                                            <a href={CRoutes.sinusInfection}>Sinus Infection</a>
                                        </li>
                                        <li className="list-unstyled">
                                            <a href={CRoutes.strepThroat}>Strep Throat</a>
                                        </li>
                                        <li className="list-unstyled">
                                            <a href={CRoutes.urinaryTractInfection}>Urinary Tract Infection</a>
                                        </li>
                                    </ul>
                                </Col>
                                <Col>
                                    <ul className="list-inline mb-2">
                                        <li className="text-white font-weight-bold">
                                            Chronic Care
                                        </li>
                                        <li className="list-unstyled">
                                            <a href={CRoutes.acidReflux}>Acid Reflux</a>
                                        </li>
                                        <li className="list-unstyled">
                                            <a href={CRoutes.asthma}>Asthma</a>
                                        </li>
                                        <li className="list-unstyled">
                                            <a href={CRoutes.antiDepression}>Anti Depression/Anxiety (refill medication)</a>
                                        </li>
                                        <li className="list-unstyled">
                                            <a href={CRoutes.diabetes}>Diabetes Type 2 Medication</a>
                                        </li>
                                        <li className="list-unstyled">
                                            <a href={CRoutes.highCholestrol}>High Cholesterol</a>
                                        </li>
                                        <li className="list-unstyled">
                                            <a href={CRoutes.hypertensionTreatments}>Hypertension Treatments (refill medication)</a>
                                        </li>
                                        <li className="list-unstyled">
                                            <a href={CRoutes.hypothyroidism}>Hypothyroidism</a>
                                        </li>
                                        <li className="list-unstyled">
                                            <a href={CRoutes.medicationRefill}>Medication Refill</a>
                                        </li>
                                        <li className="list-unstyled">
                                            <a href={CRoutes.migraine}>Migraine</a>
                                        </li>
                                        <li className="list-unstyled">
                                            <a href={CRoutes.smokeCessation}>Smoke Cessation</a>
                                        </li>
                                    </ul>
                                </Col>
                                <Col>
                                    <ul className="list-inline mb-2">
                                        <li className="text-white font-weight-bold">
                                            <a href={CRoutes.sexuallyTransmittedDiseases} className="text-white">
                                                Sexually Transmitted Diseases (STI)
                                            </a>
                                        </li>
                                        <li className="list-unstyled">
                                            <a href={CRoutes.chlamydiaGonorrhea}>Chlamydia/Gonorrhea</a>
                                        </li>
                                        <li className="list-unstyled">
                                            <a href={CRoutes.genitalHerpes}>Genital Herpes</a>
                                        </li>
                                        <li className="list-unstyled">
                                            <a href={CRoutes.trichomonas}>Trichomonas</a>
                                        </li>
                                    </ul>
                                </Col>
                                <Col>
                                    <ul className="list-inline mb-2">
                                        <li className="text-white font-weight-bold">
                                            Women's Health
                                        </li>
                                        <li className="list-unstyled">
                                            <a href={CRoutes.bacterialVaginosis}>Bacterial Vaginosis</a>
                                        </li>
                                        <li className="list-unstyled">
                                            <a href={CRoutes.birthControl}>Birth Control (Refills)</a>
                                        </li>
                                        <li className="list-unstyled">
                                            <a href={CRoutes.menstrualSuppression}>Menstrual Suppression</a>
                                        </li>
                                        <li className="list-unstyled">
                                            <a href={CRoutes.vaginalYeastInfection}>Vaginal yeast infection</a>
                                        </li>
                                        <li className="list-unstyled">
                                            <a href={CRoutes.urinaryTractInfectionForWomen}>Urinary Tract Infection</a>
                                        </li>
                                    </ul>
                                </Col>
                                <Col>
                                    <ul className="list-inline mb-2">
                                        <li className="text-white font-weight-bold">
                                            Men's Health
                                        </li>
                                        <li className="list-unstyled">
                                            <a href={CRoutes.balanitis}>Balanitis</a>
                                        </li>
                                        <li className="list-unstyled">
                                            <a href={CRoutes.erectileDysfunctions}>Erectile Dysfunction</a>
                                        </li>
                                        <li className="list-unstyled">
                                            <a href={CRoutes.jockItch} >Jock Itch</a>
                                        </li>
                                        <li className="list-unstyled">
                                            <a href={CRoutes.prematureEjaculation}>Premature Ejaculation</a>
                                        </li>
                                        <li className="list-unstyled">
                                            <a href={CRoutes.urethritis}>Urethritis</a>
                                        </li>
                                        <li className="list-unstyled">
                                            <a href={CRoutes.urinaryTractInfectionFormen}>Urinary Tract Infection</a>
                                        </li>
                                    </ul>
                                </Col>
                                <Col>
                                    <ul className="list-inline mb-2">
                                        <li className="text-white font-weight-bold">
                                            Dermatology
                                        </li>
                                        <li className="list-unstyled">
                                            <a href={CRoutes.acne}>Acne</a>
                                        </li>
                                        <li className="list-unstyled">
                                            <a href={CRoutes.dandruff}>Dandruff</a>
                                        </li>
                                        <li className="list-unstyled">
                                            <a href={CRoutes.eczema}>Eczema</a>
                                        </li>
                                        <li className="list-unstyled">
                                            <a href={CRoutes.excessiveSweating}>Excessive Sweating</a>
                                        </li>
                                        <li className="list-unstyled">
                                            <a href={CRoutes.hairLoss}>Hair Loss</a>
                                        </li>
                                        <li className="list-unstyled">
                                            <a href={CRoutes.psoriasis}>Psoriasis</a>
                                        </li>
                                        <li className="list-unstyled">
                                            <a href={CRoutes.scabies}>Scabies</a>
                                        </li>
                                        <li className="list-unstyled">
                                            <a href={CRoutes.skinInfection}>Skin Infection</a>
                                        </li>
                                        <li className="list-unstyled">
                                            <a href={CRoutes.stye}>Stye</a>
                                        </li>
                                    </ul>
                                </Col>
                                <Col>
                                    <ul className="list-inline mb-2">
                                        <li className="text-white font-weight-bold">
                                            Other
                                        </li>
                                        <li className="list-unstyled">
                                            <a href={CRoutes.molnupiravirAndPaxlovid}>Molnupiravir and Paxlovid</a>
                                        </li>
                                        <li className="list-unstyled">
                                            <a href={CRoutes.ivermectin}>Ivermectin</a>
                                        </li>
                                    </ul>
                                </Col>
                            </Row>
                        </div>
                        <div className="col-lg-12">
                            <div className="d-flex flex-column align-items-center">
                                <p className="text-muted small mb-1">&copy; TelMDCare 2024. All Rights Reserved.</p>
                                <h6>Download the App</h6>
                                <div className="d-flex flex-column flex-sm-row">
                                    <a target="blank" href="https://apps.apple.com/eg/app/telmd-care/id1575126713"><img loading="lazy" src={dApple} className="img-fluid" /></a>
                                    <a target="blank" href="https://play.google.com/store/apps/details?id=org.tgitek.telmdcare"><img src={dGoogle} loading="lazy" className="img-fluid" /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}
